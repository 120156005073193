<template>
  <div class="pages">
    <!--顶部头像-->
    <div class="headerInfo">
      <img class="headBack" src="../static/images/main/banner_null.png" width="100%"/>
      <div class="userInfo">
        <img v-if="user.Sex == '男'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon"
             :style="{border:'solid 2px #AACC03'}">
        <img v-if="user.Sex == '女'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon"
             :style="{border:'solid 2px #E75296'}">
        <span>{{ user.Name }}</span>
        <em>{{ user.Phone  | hidePhone }}</em>
      </div>
      <div class="inviteCode" @click="codeToPaste()">
        <span>我的邀请码</span>
        <div class="codeString">
          <em>{{inviteCode}}</em>
          <img src="../static/images/icon_tips_copy.png"/>

        </div>
      </div>
    </div>

    <div class="info">
      <ul>
        <li>
          <em>{{ user.Height }}</em>
          <span>身高cm</span>
        </li>
        <li>
          <em>{{ user.Age }}</em>
          <span>年龄</span>
        </li>
        <li>
          <em>{{ user.Weight }}</em>
          <span>体重kg</span>
        </li>
        <li>
          <em>{{ user.Sex }}</em>
          <span>性别</span>
        </li>
      </ul>
    </div>

    <!--会员卡信息-->
    <div class="vipInfo" v-for="s in schools" style="margin-top: 12px">
      <div class="shopName"><span> {{ s.ShopName }}</span> （{{ s.vipType == 1 ? '年' : '充值' }}会员）</div>
      <div class="infoDetail">
        <div class="chargeRecord" @click="toChargeRecord(s.ShopID)">充值记录</div>
        <div class="hourSUm">
          <span>共 {{ s.ClassHourToatal }} 课时</span>
          <em>（有效期至 {{ s.ExpTime |fmtTime }}）</em>
        </div>
        <mu-flex class="linear-progress">
          <mu-linear-progress
              mode="determinate" :size="10" color="secondary"
              :value="s.ClassHourRemain == 0 ? 100:s.ClassHourRemain / s.ClassHourToatal * 100"/>
        </mu-flex>
        <div class="limit">
          <em>剩余 {{ s.ClassHourRemain }} 课时</em>
          <span>完成 {{ s.ClassHourConsume }} 课时</span>
        </div>
      </div>
    </div>

    <div class="pageReport" v-show="visiblePageReport">
      <!--中间4个标签页-->
      <div class="tag">

        <!--第一行内容-->
        <div>
          <!--总卡路里-->
          <div class="card">
            <div class="row1">
              <img src="../static/images/report/report_cle_total.png">
              <em>总卡路里</em>
            </div>
            <div class="row2">
              <span>{{ cardRs.calorieTotal }}</span>
              <em>千卡</em>
            </div>
            <div class="row3">
              <span>俱乐部内排名</span>
              <em>{{ cardRs.calorieTotalOrder }}</em>
            </div>
          </div>
          <!--总CK-->
          <div class="card" style="background: linear-gradient(rgba(255,236,211,0.16), #FFECD3);">
            <div class="row1">
              <img src="../static/images/report/report_ck.png">
              <em>总CK</em>
            </div>
            <div class="row2">
              <span>{{ cardRs.CKTotal }}</span>
            </div>
            <div class="row3">
              <span>俱乐部内排名</span>
              <em>{{ cardRs.CKTotalOrder }}</em>
            </div>
          </div>
        </div>

        <!--第二行内容-->
        <div>
          <!--总上课次数-->
          <div class="card" style="background: linear-gradient(rgba(211,231,255,0.3), #D3E7FF);">
            <div class="row1">
              <img src="../static/images/report/report_class.png">
              <em>总上课次数</em>
            </div>
            <div class="row2">
              <span>{{ cardRs.classTotal }}</span>
              <em>次</em>
            </div>
            <div class="row3">
              <span>俱乐部内排名</span>
              <em>{{ cardRs.classTotalOrder }}</em>
            </div>
          </div>
          <!--平均卡路里-->
          <div class="card" style="background: linear-gradient(rgba(255,211,252,0.19), #FFD3FC);">
            <div class="row1">
              <img src="../static/images/report/report_cle_avg.png">
              <em>平均卡路里</em>
            </div>
            <div class="row2">
              <span>{{ cardRs.calorieAverage }}</span>
              <em>千卡/小时</em>
            </div>
            <div class="row3">
              <span>俱乐部内排名</span>
              <em>{{ cardRs.calorieAverageOrder }}</em>
            </div>
          </div>
        </div>

        <!--第三行内容-->
        <div>
          <!--总上课次数-->
          <div class="cardOneColumn" @click="toPage('/fitnessRecord')"
               style="background: linear-gradient(rgba(211,231,255,0.3), #AFE4D4);width: 93%">
            <div class="col1">
              <i/>
              <em>测评记录</em>
            </div>
            <div class="col2">
              <div>
                <span>最近一次</span>
              </div>
              <div>
                <em v-if="cardRs.FtoId == 0">暂无历史测评记录</em>
                <em v-else>{{ cardRs.fitnessLastTime |filterTime }}</em>
              </div>
            </div>
            <div class="col3">
              <span>...</span>
            </div>
          </div>
        </div>

        <!--第四行内容-->
        <div>
          <!--总上课次数-->
          <div class="cardOneColumn" @click="toLessonRecommend()"
               style="background: linear-gradient(rgba(255,255,255,0.1), #B1CEE5);width: 93%">
            <div class="col1">
              <i style="background: #33CAF7"/>
              <em>课程推荐</em>
            </div>
            <div class="col2">
              <div>
                <span>目标开始时间</span>
              </div>
              <div>
                <em v-if="cardRs.TtoId == 0">暂无推荐记录</em>
                <em v-else>{{ cardRs.TtoBt |filterTime }}</em>
              </div>
            </div>
            <div class="col3">
              <span>...</span>
            </div>
          </div>
        </div>

        <!--第五行内容-->
        <div>
          <!--日常训练-->
          <div class="cardOneColumn" @click="toTrainingDaily()"
               style="background: linear-gradient(rgba(255,255,255,0.1), #B1CEE5);width: 93%">
            <div class="col1">
              <i style="background: #33CAF7"/>
              <em>日常训练</em>
            </div>
            <div class="col2">
              <div>
                <span>训练计划时间</span>
              </div>
              <div>
                <em v-if="cardRs.LastDtoInfo.DtoId == 0">暂无训练计划</em>
                <em v-else>{{ cardRs.LastDtoInfo.BeginDate }} 至 {{ cardRs.LastDtoInfo.EndDate }}</em>
              </div>
            </div>
            <div class="col3">
              <span>...</span>
            </div>
          </div>
        </div>

        <!--第六行内容-->
        <div>
          <!--充值记录-->
          <div class="cardOneColumn" @click="toChargeRecord()"
               style="background: linear-gradient(rgba(255,255,255,0.1), #FFECD3);width: 93%">
            <div class="col1">
              <i style="background: #FFA310"/>
              <em>充值记录 </em>
            </div>
            <div class="col2">
              <div>
                <span>最近一次充值</span>
              </div>
              <div>
                <em v-if="cardRs.LastChargeInfo.PayId == 0">暂无充值</em>
                <em v-else>{{ cardRs.LastChargeInfo.ChargeTime | filterTime }}</em>
              </div>
            </div>
            <div class="col3">
              <span>...</span>
            </div>
          </div>
        </div>

      </div>

      <!--卡路里统计-->
      <div class="reportCalorie">
        <div class="title">
          <i class="fa-square"/>
          <span>卡路里月统计表</span>
        </div>
        <div>
          <div class="chartCalorie" id="calorieMonth"/>
        </div>
      </div>
      <!--CK月统计-->
      <div class="reportCalorie">
        <div class="title" style="color: #FFA310">
          <i class="fa-square" style="background: #FFA310"/>
          <span>CK月统计表</span>
        </div>
        <div>
          <div class="chartCalorie" id="ckMonth"/>
        </div>
      </div>
      <!--课时月统计-->
      <div class="reportCalorie">
        <div class="title" style="color: #028FE1">
          <i class="fa-square" style="background: #028FE1"/>
          <span>上课次数统计表</span>
        </div>
        <div>
          <div class="chartCalorie" id="classMonth"/>
        </div>
      </div>
      <!--课程统计-->
      <div class="reportCalorie" style="background: #F2F2F2" v-show="true">
        <div class="title" style="color: #E75296">
          <i class="fa-square" style="background: #E75296"/>
          <span>课程统计</span>
          <span style="font-size: 10px;color: #C9C9C9;margin-left: 10px">(共 {{ classRs.length }} 课程)</span>
          <!--          <span style="float: right;margin-right: 10px;color: #909090;padding-bottom: 10px">...</span>-->
        </div>

        <ul class="classReport">
          <li v-for=" classItem in classRs"><!--border: solid 1px #37CB00;-->
            <span class="className" :style="{ color: classItem.ClassColor, border: 'solid 1px '+classItem.ClassColor }">{{
                classItem.ClassName
              }}</span>
            <div class="calorieToTal">
              <em>{{ classItem.TotalCle }}</em>
              <span>总卡路里</span>
            </div>
            <div class="calorieToTal">
              <em>{{ classItem.TotalCk }}</em>
              <span>总ck</span>
            </div>
            <div class="calorieToTal">
              <em>{{ classItem.TotalClassNum }}</em>
              <span>总上课次数</span>
            </div>
          </li>

        </ul>
      </div>
    </div>

    <!--查看全部课程-->
    <div class="classDetailInfo" @click="showAllLessonListener" v-show="false">
      <div>
        <span>课程详细信息</span>
        <span style="float: right;margin-right: 10px;color: #909090;padding-bottom: 10px">...</span>
      </div>
    </div>

    <!--以下是控制隐藏项-->
    <!--全部课程-->
    <div class="pageClass" v-show="visiblePageClass" @click="showLessonDetail()">
      <mu-load-more
          @refresh="refresh"
          :refreshing="refreshing"
          :loading="loading"
          @load="load"
      >
        <em>全部课程</em>
        <ul class="dayList">
          <li class="dayDetail">
            <span class="dayTitle"> 2021年7月22日 星期四</span>
            <span class="className" :style="{ background: testColor,border: testColor2}">体能训练</span>
            <div class="sportDetail">
              <ul>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FF5800;"/>
                    1278
                  </em>
                  <span>卡路里</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFA310;"/>
                    732
                  </em>
                  <span>运动强度EI</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFE13C;"/>
                    89.2
                  </em>
                  <span>CK</span>
                </li>
              </ul>
              <div class="sportDuration">
                <span>运动时长</span>
                <em>{{ user.Weight }}</em>
                <span>分钟</span>
              </div>
              <div class="sportView">
                <span>详细信息</span>
                <em>&gt;</em>
              </div>
            </div>
          </li>

          <li class="dayDetail">
            <span class="dayTitle"> 2021年7月22日 星期四</span>
            <span class="className" :style="{ background: testColor,border: testColor2}">体能训练</span>
            <div class="sportDetail">
              <ul>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FF5800;"/>
                    1278
                  </em>
                  <span>卡路里</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFA310;"/>
                    732
                  </em>
                  <span>运动强度EI</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFE13C;"/>
                    89.2
                  </em>
                  <span>CK</span>
                </li>
              </ul>
              <div class="sportDuration">
                <span>运动时长</span>
                <em>{{ user.Weight }}</em>
                <span>分钟</span>
              </div>
              <div class="sportView">
                <span>详细信息</span>
                <em>&gt;</em>
              </div>
            </div>
          </li>
          <li class="dayDetail">
            <span class="dayTitle"> 2021年7月22日 星期四</span>
            <span class="className" :style="{ background: testColor,border: testColor2}">体能训练</span>
            <div class="sportDetail">
              <ul>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FF5800;"/>
                    1278
                  </em>
                  <span>卡路里</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFA310;"/>
                    732
                  </em>
                  <span>运动强度EI</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFE13C;"/>
                    89.2
                  </em>
                  <span>CK</span>
                </li>
              </ul>
              <div class="sportDuration">
                <span>运动时长</span>
                <em>{{ user.Weight }}</em>
                <span>分钟</span>
              </div>
              <div class="sportView">
                <span>详细信息</span>
                <em>&gt;</em>
              </div>
            </div>
          </li>
          <li class="dayDetail">
            <span class="dayTitle"> 2021年7月22日 星期四</span>
            <span class="className" :style="{ background: testColor,border: testColor2}">体能训练</span>
            <div class="sportDetail">
              <ul>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FF5800;"/>
                    1278
                  </em>
                  <span>卡路里</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFA310;"/>
                    732
                  </em>
                  <span>运动强度EI</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFE13C;"/>
                    89.2
                  </em>
                  <span>CK</span>
                </li>
              </ul>
              <div class="sportDuration">
                <span>运动时长</span>
                <em>{{ user.Weight }}</em>
                <span>分钟</span>
              </div>
              <div class="sportView">
                <span>详细信息</span>
                <em>&gt;</em>
              </div>
            </div>
          </li>

          <li class="dayDetail">
            <span class="dayTitle"> 2021年7月22日 星期四</span>
            <span class="className" :style="{ background: testColor,border: testColor2}">体能训练</span>
            <div class="sportDetail">
              <ul>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FF5800;"/>
                    1278
                  </em>
                  <span>卡路里</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFA310;"/>
                    732
                  </em>
                  <span>运动强度EI</span>
                </li>
                <li>
                  <em>
                    <i class="fa-circle" style="background: #FFE13C;"/>
                    89.2
                  </em>
                  <span>CK</span>
                </li>
              </ul>
              <div class="sportDuration">
                <span>运动时长</span>
                <em>{{ user.Weight }}</em>
                <span>分钟</span>
              </div>
              <div class="sportView">
                <span>详细信息</span>
                <em>&gt;</em>
              </div>
            </div>
          </li>

        </ul>
      </mu-load-more>
    </div>

    <!--课程详情-->
    <div class="pageDetail" v-show="visiblePageDetail">
      <span class="btnBack">返回</span>
      <!--课程标题-->
      <div class="classDetail">
        <div class="detailTitle">
          <span class="className">体能训练</span>
          <span class="classTime">10:00-12:00</span>
        </div>
      </div>
      <!--会员基本信息-->
      <div class="memberInfo">
        <span>上课时基本信息 ｜年龄 </span><em>11</em>
        <span> 岁 ｜身高 </span><em>11</em>
        <span> cm ｜ 体重 </span><em>11</em>
        <span> Kg</span>
      </div>

      <!--课程基本信息-->
      <div class="classInfo">
        <ul style="border-top: 1px solid #CCC; padding-top: 15px">
          <li>
            <em>1233</em>
            <span>卡路里</span>
          </li>
          <li>
            <em>{{ user.Age }}</em>
            <span>CK</span>
          </li>
          <li>
            <em>{{ user.Weight }}</em>
            <span>EI</span>
          </li>
        </ul>

        <ul>
          <li>
            <em>87分钟</em>
            <span>上课时长</span>
          </li>
          <li>
            <em>{{ user.Age }}</em>
            <span>排名</span>
          </li>
          <li>
            <em>9</em>
            <span>上课人数</span>
          </li>
        </ul>
      </div>

      <h5 class="sportPower">运动强度</h5>
      <!--运动强度柱状图-->
      <!--      <div class="chartCalorie" id="activePercent" style="margin-top: 20px;width: 100%;margin-left: 0"/>-->
      <div class="chartCalorie" id="activePercent" :style="{width:screenWidth,marginTop:'20px',marginLeft:'0'}"/>

      <!--运动心率折线图-->
      <!--      <div class="chartCalorie" id="sportHeartRate" style="margin-top: 10px;margin-left: 0;"/>-->
      <div class="chartCalorie" id="sportHeartRate" :style="{width:screenWidth,marginTop:'10px',marginLeft:'0'}"/>
      <!--动作评分-->
      <div class="pageScore" v-show="visiblePageScore">
        <div style="height: 10px;background: #F2F2F2;"></div>
        <div class="scoreTitle">
          <span>动作质量反馈</span>
        </div>
        <ul class="scoreContent">
          <li>
            <span class="left">总分占比</span>
            <span class="right">
              255
              <em>完成很棒</em>
            </span>
          </li>
          <li>
            <span class="left">俯卧撑</span>
            <span class="right">
              1229
              <em>完成很棒</em>
            </span>
          </li>
          <li>
            <span class="left">原地蹲</span>
            <span class="right">
              1278
              <em>完成很棒</em>
            </span>
          </li>
          <li>
            <span class="left">箭步蹲</span>
            <span class="right">
              2332
              <em>完成很棒</em>
            </span>
          </li>
          <li>
            <span class="left">臀桥</span>
            <span class="right">
              4345
              <em>完成很棒</em>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn_center">
      <span class="btn btn-md login_btn" @click="userLogout">退出登录</span>
    </div>
    <bottomTab :curTab="thisTab"/>
  </div>
</template>

<script>
import axios from 'axios';
import bottomTab from '../components/bottomTab'
import {
  MyShopQuery,
  VipUserSelfQuery,
  PersonCardStatistics,
  PersonClassStatistics,
  PersonMonthStatistics, VipUserSelfInviteCodeQuery,
} from '../api/getApiRes.js'

let qs = require('qs');
import Global from '../Global.js'
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')

export default {
  data() {
    return {
      thisTab: '我的运动',
      testColor2: '#F2F2F2',
      testColor: '#FFE58D',
      screenWidth: document.body.clientWidth + 'px',
      headImgUrl: require('../static/images/mine/header_default.png'),
      //控制页面开合
      visiblePageReport: true,//四个card和折线图显示
      visiblePageClass: false,//全部课程显示
      visiblePageDetail: false,//课程详情显示
      visiblePageScore: false,//课程有动作评分显示
      scrollTop: 190,
      //折线图源数据
      chartCleRs: {},
      chartCkRs: {},
      chartClassRs: {},
      active: 0,
      inviteCode:'',
      user: {
        Name: '',
        Age: '',
        Height: '',
        Weight: '',
        Phone: '',
        Sex: '',
        Head: '',
        CKToTal: 0,
        CKMonth: 0,
        CalorieMonth: 0,
        CalorieTotal: 0,
      },
      cardRs: {
        calorieTotal: 0,
        calorieTotalOrder: 0,
        CKTotal: 0,
        CKTotalOrder: 0,
        classTotal: 0,
        classTotalOrder: 0,
        calorieAverage: 0,
        calorieAverageOrder: 0,
        fitnessLastTime: '暂无历史记录',
        FtoId: 0,
        TtoId: 0,
        LastDtoInfo: {},
        LastChargeInfo: {},
      },
      classRs: [],
      schools: [],
      refreshing: false,
      loading: false,
      start: 1,
      tableMax: 6,
    }
  },
  watch: {
    '$route'(to) {
      if (to.name == 'report') {
        let that = this;
        that.getUserInviteCode();//获取用户邀请码
        that.getUserInfo();//获取用户基本信息
        that.getUserVipInfo();//获取用户会员卡信息
        that.getCardInfo();//获取四个卡片信息
        that.getChartDataRs();//获取折线图所需数据源
        that.getClassReport();//获取课程统计
        that.visiblePageReport = true;
        that.visiblePageClass = false;
        that.visiblePageDetail = false;
        that.visiblePageScore = false;
      }
    },

  },
  destroyed() {
  },
  mounted() {
    let that = this;
    that.getUserInviteCode();//获取用户邀请码
    that.getUserInfo();//获取用户基本信息
    that.getUserVipInfo();//获取用户会员卡信息
    that.getCardInfo();//获取四个卡片信息
    that.getChartDataRs();//获取折线图所需数据源
    that.getClassReport();//获取课程统计
    that.visiblePageReport = true;
    that.visiblePageClass = false;
    that.visiblePageDetail = false;
    that.visiblePageScore = false;
  },
  methods: {
    pasterMethod_1(pasteText){
      let textArea = document.createElement("textarea");
      textArea.value = pasteText;
      // 2.使用定位，阻止页面滚动
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        this.$toast.message('复制成功');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      // 3.移除元素
      document.body.removeChild(textArea);
    },
    codeToPaste(){
      let that = this;
      if (!navigator.clipboard) {
        that.pasterMethod_1(that.inviteCode);
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        that.$toast.message('复制成功');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    //获取用户邀请码
    getUserInviteCode() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      VipUserSelfInviteCodeQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.inviteCode = json.InviteCode;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取用户基本信息
    getUserInfo() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      VipUserSelfQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.user = json.Rs;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取用户会员卡信息
    getUserVipInfo() {
      let that = this;
      let param = {
        token: localStorage.token,
      };
      let postdata = qs.stringify(param);
      MyShopQuery(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.schools = json.Rs;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取四个卡片信息
    getCardInfo() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      PersonCardStatistics(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          for (let i in json.Rs) {
            if (json.Rs[i].StaName == '总卡路里') {
              that.cardRs.calorieTotal = json.Rs[i].StaValue;
              that.cardRs.calorieTotalOrder = json.Rs[i].StaOrder;
            }
            if (json.Rs[i].StaName == '总CK') {
              that.cardRs.CKTotal = json.Rs[i].StaValue;
              that.cardRs.CKTotalOrder = json.Rs[i].StaOrder;
            }
            if (json.Rs[i].StaName == '总上课次数') {
              that.cardRs.classTotal = json.Rs[i].StaValue;
              that.cardRs.classTotalOrder = json.Rs[i].StaOrder;
            }
            if (json.Rs[i].StaName == '平均卡路里') {
              that.cardRs.calorieAverage = json.Rs[i].StaValue;
              that.cardRs.calorieAverageOrder = json.Rs[i].StaOrder;
            }
          }
          if (json.LastFtoInfo) {
            if (json.LastFtoInfo.FtoId != 0) {
              that.cardRs.FtoId = json.LastFtoInfo.FtoId;
              that.cardRs.fitnessLastTime = json.LastFtoInfo.CreatedAt;
            }
          }
          if (json.LastTtoInfo) {
            if (json.LastTtoInfo.TtoId != 0) {
              that.cardRs.TtoId = json.LastTtoInfo.TtoId;
              that.cardRs.TtoBt = json.LastTtoInfo.TtoBt;
            }
          }
          if (json.LastDtoInfo) {
            if (json.LastDtoInfo.TtoId != 0) {
              that.cardRs.LastDtoInfo = json.LastDtoInfo;
            }
          }
          if (json.LastChargeInfo) {
            that.cardRs.LastChargeInfo = json.LastChargeInfo;
          }
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取折线图所需数据源
    getChartDataRs() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      PersonMonthStatistics(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.chartCleRs = json.Rs.CleRs;
          that.chartCkRs = json.Rs.CkRs;
          that.chartClassRs = json.Rs.ClassNumRs;

          this.loadCalMonthChart();//卡路里月统计折线图
          this.loadCkMonthChart();//CK月统计折线图
          this.loadClassMonthChart();//上课次数统计柱状图
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取课程统计
    getClassReport() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      PersonClassStatistics(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.classRs = json.Rs;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //控制全部课程开合
    showAllLessonListener() {
      let that = this;
      // that.visiblePageReport = false;
      // that.visiblePageClass = true;
      this.$router.push({path: '/reportLesson'});
    },
    //控制课程详情开合
    showLessonDetail() {
      let that = this;
      that.visiblePageReport = false;
      that.visiblePageClass = false;
      that.visiblePageDetail = true;
      that.visiblePageScore = true;
      this.loadActivePercentChart();//课程运动强度柱状图
      this.loadSportHrChart();//课程运动心率折线图
    },
    refresh() {
    },
    load() {
    },
    //卡路里月统计折线图
    loadCalMonthChart() {
      let echarts = require('echarts');
      let myChart = echarts.init(document.getElementById('calorieMonth'));
      let dataKey = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      let dataValue = [0, 0, 0, 3705, 120, 520, 7820, 3206, 1610];

      let that = this;
      dataKey = that.chartCleRs.DataX;
      dataValue = that.chartCleRs.DataY;

      if (dataValue[dataValue.length - 1] == 0) {
        dataValue.pop();
      }

      let dataMinValue = 8000, dataMaxValue = 0;
      for (let t in dataValue) {
        if (dataMaxValue < dataValue[t]) {
          dataMaxValue = dataValue[t];
        }
        if (dataMinValue > dataValue[t]) {
          dataMinValue = dataValue[t];
        }
      }

      let options = {
        color: ['#FF5800'],
        // 提示框组件
        tooltip: {},
        xAxis: {
          // name:'月份',
          data: dataKey
        },
        yAxis: {
          min: (dataMinValue > 200 ? dataMinValue : 200) - 200,
          max: dataMaxValue + 200,
          type: 'value',
          name: '千卡',
        },
        series: [{
          name: '千卡',
          type: 'line',
          itemStyle: {normal: {label: {show: true}}},// 显示数值
          data: dataValue
        }],
        grid: {
          left: 45//表格左边距
        }
      };
      myChart.setOption(options);
    },
    //CK月统计折线图
    loadCkMonthChart() {
      let echarts = require('echarts');
      let myChart = echarts.init(document.getElementById('ckMonth'));
      let dataKey = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      let dataValue = [60, 20, 33, 25, 10, 2, 31, 42, 10, 43, 55, 62];

      let that = this;
      dataKey = that.chartCkRs.DataX;
      dataValue = that.chartCkRs.DataY;

      if (dataValue[dataValue.length - 1] == 0) {
        dataValue.pop();
      }

      let dataMinValue = 500, dataMaxValue = 0;
      for (let t in dataValue) {
        if (dataMaxValue < dataValue[t]) {
          dataMaxValue = dataValue[t];
        }
        if (dataMinValue > dataValue[t]) {
          dataMinValue = dataValue[t];
        }
      }

      let options = {
        color: ['#FFA310'],
        tooltip: {},
        xAxis: {
          data: dataKey
        },
        yAxis: {
          min: (dataMinValue > 50 ? dataMinValue : 50) - 50,
          max: dataMaxValue + 50,
        },
        series: [{
          name: 'CK',
          type: 'line',
          itemStyle: {normal: {label: {show: true}}},// 显示数值
          data: dataValue
        }],
        grid: {
          left: 45//表格左边距
        }
      };
      myChart.setOption(options);
    },
    //上课次数统计柱状图
    loadClassMonthChart() {
      let echarts = require('echarts');
      let myChart = echarts.init(document.getElementById('classMonth'));
      let dataKey = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      let dataValue = [60, 20, 2, 10, 43, 55, 31, 42, 33, 25, 10, 62];

      let that = this;
      dataKey = that.chartClassRs.DataX;
      dataValue = that.chartClassRs.DataY;

      if (dataValue[dataValue.length - 1] == 0) {
        dataValue.pop();
      }

      let options = {
        color: ['#028FE1'],
        tooltip: {},
        xAxis: {
          data: dataKey
        },
        yAxis: {},
        series: [{
          // name: '次数',
          type: 'bar',
          barWidth: 10,//柱图宽度
          itemStyle: {normal: {label: {show: true}}},// 显示数值
          data: dataValue
        }],
        grid: {
          left: 45//表格左边距
        }
      };
      myChart.setOption(options);
    },
    //课程运动强度柱状图
    loadActivePercentChart() {
      let echarts = require('echarts');
      let sumData = 0, totalData = 89;
      let myChart = echarts.init(document.getElementById('activePercent'));
      // let dataLine = [2, 11, 31, 22, 21, 14];
      let dataLine = [4, 1, 13, 22, 40, 22];
      for (let nums in dataLine) {
        sumData += dataLine[nums];
      }
      sumData = 100;
      // 绘制图表
      let option = {
        // 设置图表的位置
        grid: [
          {
            left: '8%',//左侧留白
            top: '12%',//顶部留白
            bottom: '8%',//底部留白
            width: '80%',//宽度占比
            // width: 500,
            containLabel: true
          },
          {
            top: '12%',
            bottom: '8%',
            containLabel: true
          }
        ],
        // 提示框组件
        tooltip: {
          trigger: 'axis', // 触发类型, axis: 坐标轴触发
          axisPointer: {
            // 指示器类型  'line' 直线指示器 'shadow' 阴影指示器 'none' 无指示器
            // 'cross' 十字准星指示器 其实是种简写，表示启用两个正交的轴的 axisPointer
            type: 'none'
          },
          color: '#cdd3ee', // 文字颜色
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式 折线（区域）图、柱状（条形）图、K线图
          // {a}（系列名称），{b}（类目值），{c}（数值）, {d}（无）
          formatter: '{b}<br/>{a0}: {c}%'
        },
        // X轴
        xAxis: [{
          max: sumData,
          show: false
        }],
        yAxis: [{
          axisTick: 'none',
          axisLine: 'none',
          offset: '10',
          // inverse: true,
          axisLabel: {
            color: '#000000', //y轴字体颜色
            fontSize: '16'
          },
          // data: ['激活放松', '动态热身', '脂肪燃烧', '糖分消耗', '心肺训练', '峰值训练']
          data: ['峰值训练', '心肺训练', '糖分消耗', '脂肪燃烧', '动态热身', '激活放松']
        },
          {
            axisTick: 'none',
            axisLine: 'none',
            show: false,
            axisLabel: {
              color: '#ffffff',
              fontSize: '16'
            },
            data: [1, 1, 1, 1, 1, 1]
          }, {
            axisLine: {
              lineStyle: {
                color: 'rgba(0,0,0,0)' //y轴线颜色
              }
            },
            data: []
          },
          {  //设置柱状图右边参数
            // show: true,
            // inverse: true,
            // data: dataLine,
            axisLine: {
              show: false
            },
            // splitLine: {
            //   show: false
            // },
            // axisTick: {
            //   show: false
            // }
          },
        ],
        // 系列列表
        series: [{
          name: '占比',
          type: 'bar',
          stack: 'b',
          yAxisIndex: 0,
          data: dataLine,
          color: '#ffffff',
          fontSize: '16',
          label: {
            show: true,
            position: 'right',
            distance: 5,
            formatter: function (param) {
              // let sportWeight = (param.value / sumData).toFixed(2) * 100;
              // return parseInt(sportWeight) + '%'
              return param.value + '%'
            },
          },
          barWidth: 11,
          itemStyle: {
            color: function (params) {
              //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
              // let colorList = ['#028FE1', '#6D29FA', '#0AB105', '#B9CB01', '#EA8813', '#D11122'];
              let colorList = ['#D11122', '#EA8813', '#B9CB01', '#0AB105', '#6D29FA', '#028FE1'];
              return colorList[params.dataIndex];
            },
            borderRadius: [100, 100, 100, 100, 100, 100],
          },
          z: 2
        }, { //背景灰框
          name: '白框',
          type: 'bar',
          yAxisIndex: 1,
          barGap: '-100%',//设置-100% 则表示灰色柱状图与红色柱状图重合
          data: [99.8, 99.9, 99.9, 99.9],
          barWidth: 11,
          itemStyle: {
            color: '#DDDDDD',
            borderRadius: [100, 100, 100, 100, 100, 100],
          },
          z: 1  // 设置维度越高这表示覆盖低的
        }, //进度条底框
          {
            name: '外框',
            type: 'bar',
            yAxisIndex: 2,
            barGap: '-100%',
            data: [100, 100, 100, 100, 100, 100],
            barWidth: 13,
            label: {//右边的文字
              show: true,
              position: 'right',
              distance: 5,
              color: '#000000',
              formatter: function (data) {
                if (dataLine[data.dataIndex] < 1) {
                  return '0分钟';
                }
                let actualDuration = parseInt(dataLine[data.dataIndex] / 100 * totalData);
                if (actualDuration == 0) {
                  return '<1分钟';
                } else {
                  return actualDuration + "分钟";
                }
              },
            },
            //进度条底色
            itemStyle: {
              color: '#DDDDDD',
              borderRadius: [100, 100, 100, 100, 100, 100],
            },
            z: 0
          }
        ]
      }
      myChart.setOption(option);
    },
    //课程运动心率折线图
    loadSportHrChart() {
      let date = [];
      let data = [];
      let dateNow = new Date();
      for (let i = 1; i < 3300; i++) {
        // date.push(new Date(dateNow).toLocaleString('zh', {hour12: false}));
        date.push(i);
        data.push(Math.floor(Math.random() * (30 + 1) + 80));
      }

      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('sportHeartRate'));
      let options = {
        tooltip: {
          // 当trigger为’item’时只会显示该点的数据，为’axis’时显示该列下所有坐标轴所对应的数据。
          trigger: 'axis',
          // 提示框的位置
          position: function (pt) {
            return [pt[0], '50%'];
          }
        },
        title: {
          left: 'center',
          text: '心率趋势'
        },
        // toolbox：这是ECharts中的工具栏。内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        // toolbox: {
        // feature 各工具配置项: dataZoom 数据区域缩放;restore 配置项还原;saveAsImage下载为图片;magicType动态类型切换
        // feature: {
        //   dataZoom: {
        //     yAxisIndex: 'none'  // y轴不缩放，Index默认为0
        //   },
        // restore: {},
        // saveAsImage: {}
        // ,magicType: {
        //     type: ['bar', 'line']
        // }
        // }
        // },
        xAxis: {
          type: 'category', // category为一级分类,适用于离散的类目数据
          boundaryGap: false,  // 无间隙
          data: date
        },
        yAxis: {
          type: 'value', // 'value' 数值轴，适用于连续数据。
          boundaryGap: [0, '100%'] // 分别表示数据最小值和最大值的延伸范围，可以直接设置数值或者相对的百分比，
        },
        // 内置于坐标系中，使用户可以在坐标系上通过鼠标拖拽、鼠标滚轮、手指滑动（触屏上）来缩放或漫游坐标系
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 1
        }, {
          start: 0,
          end: 5,
          //柄的 icon 形状，支持路径字符串
          //handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          //控制手柄的尺寸，可以是像素大小，也可以是相对于 dataZoom 组件宽度的百分比，默认跟 dataZoom 宽度相同。
          handleSize: '20%',
          handleStyle: {
            color: 'pink',
            shadowBlur: 3,      // shadowBlur图片阴影模糊值，shadowColor阴影的颜色
            shadowColor: 'red',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }],
        series: [
          {
            name: '瞬时心率',
            type: 'line',
            // smooth: true,  // 开启平滑处理。true的平滑程度相当于0.5
            symbol: 'none', // 标记的图形。
            sampling: 'average', //  取过滤点的平均值
            itemStyle: {
              lineStyle: {
                color: '#ff6f4a'/*折线的颜色*/
              },
              color: "#ff6f4a"/*图例(legend)的颜色,不是图例说明文字的颜色*/
            },
            areaStyle: {    // 区域填充样式。
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#ff6f4a'},
                    /* {offset: 0.5, color: '#c4e1ff'},*/
                    {offset: 1, color: '#ecf5ff'}
                  ]
              )
            },
            data: data
          }
        ]
      };
      myChart.setOption(options);
    },
    toPage(path) {
      this.$router.push({path: path, query: {ttoId: this.cardRs.TtoId, viewType: 1}});
    },
    toLessonRecommend() {
      if (this.cardRs.TtoId == 0) {
        this.$router.push({path: 'lessonRecommendRecord'});
      } else {
        this.$router.push({path: 'lessonRecommendDetail', query: {ttoId: this.cardRs.TtoId, viewType: 1}});
      }
    },
    toTrainingDaily() {
      if (this.cardRs.LastDtoInfo.DtoId == 0) {
        this.$router.push({path: 'dailyTrainingList'});
      } else {
        this.$router.push({
          path: 'dailyTrainingDetail',
          query: {
            dtoId: this.cardRs.LastDtoInfo.DtoId,
            beginDate: this.cardRs.LastDtoInfo.BeginDate,
            endDate: this.cardRs.LastDtoInfo.EndDate,
            viewType: 1
          }
        });
      }
    },
    toChargeRecord(shopId) {
      this.$router.push({path: 'chargeRecord',query:{shopId:shopId}});
    },
    //退出登录
    userLogout() {
      let that = this;
      this.$confirm('是否退出账号？', '提示', {
        type: 'warning'
      }).then(({result}) => {
        if (result) {
          localStorage.token = '';
          that.$router.push({path: '/login'});
        } else {
          that.$toast.message('点击了取消');
        }
      });
    }
  },
  filters: {
    hidePhone: function (val) {
      let str = String(val)
      let len = str.length;
      if (len >= 7) {
        let reg = str.slice(-8, -4)
        return str.replace(reg, "****")
      } else if (len < 7 && len >= 6) {
        let reg = str.slice(-4, -2)
        return str.replace(reg, "**")
      }
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
    fmtTime: function (value) {
      if (!value) {
        return 0
      } else {
// js默认使用毫秒
        value = value * 1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == 'report') {
        vm.getUserInfo();
      }
    });
  },
  components: {
    bottomTab
  }
}
</script>

<style scoped>
.pages {
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #F2F2F2;
}

.headerInfo {
  position: relative;
}

.headerInfo .headBack {
  height: 130px;
  position: absolute;
  z-index: 1;
}

.headerInfo .userInfo {
  left: 42%;
  top: 1.25rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .userInfo span {
  font-size: 16px;
  color: white;
  display: block;
  margin-top: 2px;
}

.headerInfo .userInfo em {
  font-size: 12px;
  display: block;
}
.headerInfo .inviteCode {
  right: 4%;
  top: 2rem;
  height: 50px;
  width: 100px;
  position: absolute;
  z-index: 2;
  text-align: left;
}

.inviteCode span{
  font-size: 14px;
  color: #ffffff;
}

.inviteCode .codeString em{
  font-size: 16px;
  color: #FF81BB;
}

.inviteCode .codeString img{
  margin-left: 5px;
  width: 12px;
  height: 12px;
}

.round_icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.info {
  background: #fff;
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 135px;
}

.info ul {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 10px auto;
}

.info li {
  width: 25%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  float: left;
  text-align: center;
}

.info li:nth-child(2) {
  border-color: #e2e2e2;
}

.info li:nth-child(3) {
  border-color: #e2e2e2;
  border-left: 0;
}

.info li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #363636;
  font-size: 20px;
  text-align: center;
}

.info li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: center;
}

.pageReport {
  background: #f2f2f2;
  margin-top: 10px;
}

.tag {
  overflow: auto;
  padding-bottom: 20px;
  background: white;
}

.tag .card {
  width: 45%;
  float: left;
  height: 106px;
  margin-left: 3%;
  margin-top: 20px;
  border-radius: 13px;
  background: linear-gradient(rgba(255, 212, 211, 0.16), #FFD4D3);
}

.tag .card .row1 img {
  margin-left: 10px;
  margin-top: 10px;
  width: 17px;
  height: 18px;
  float: left;
}

.tag .card .row1 em {
  font-family: "PingFang SC";
  font-size: 12px;
  line-height: 40px;
  margin-left: 7px;
  color: #3b3b3b;
}

.tag .card .row2 span {
  font-family: "PingFang SC";
  font-size: 22px;
  font-weight: bold;
  margin-left: 35px;
  color: #363636;
}

.tag .card .row2 em {
  font-family: "PingFang SC";
  font-size: 10px;
  margin-left: 5px;
  color: #909090;
}

.tag .card .row3 span {
  font-family: "PingFang SC";
  font-size: 12px;
  margin-left: 35px;
  color: #363636;
}

.tag .card .row3 em {
  font-family: "PingFang SC";
  font-size: 12px;
  margin-left: 5px;
  color: #363636;
}

.tag .cardOneColumn {
  float: left;
  height: 66px;
  margin-left: 3%;
  margin-top: 20px;
  border-radius: 13px;
  background: linear-gradient(rgba(255, 212, 211, 0.16), #FFD4D3);
}

.tag .cardOneColumn .col1 i {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 250px;
  background: #e75296;
  margin-bottom: 2px;
  margin-right: 10px;
  margin-left: 10px;
}

.tag .cardOneColumn .col1 {
  line-height: 66px;
  width: 110px;
  float: left;
  font-size: 20px;
}

.tag .cardOneColumn .col2 {
  float: left;
  margin-top: 12px;
  margin-left: 25px;
}

.tag .cardOneColumn .col2 em {
  font-size: 12px;
}

.tag .cardOneColumn .col2 span {
  color: #7E7E7E;
  font-size: 14px;
}

.tag .cardOneColumn .col3 {
  float: right;
}

.tag .cardOneColumn .col3 span {
  margin-right: 20px;
  font-size: 22px;
  color: #7E7E7E;
}

.reportCalorie {
  margin-top: 10px;
  background: #FFF;
  color: #FF5800;
}

.reportCalorie .title {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #FFF;
}

.fa-square {
  background: #FF5800;
  height: 8px;
  width: 8px;
  float: left;
  margin-left: 15px;
  margin-top: 5px;
  margin-right: 5px;
}

.fa-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #c40b0b;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.sportPower {
  font-size: 18px;
  margin-bottom: -30px;
}

.chartCalorie {
  text-align: center;
  width: 95%;
  height: 200px;
  margin-top: -35px;
  margin-left: 5%;
}

.classReport {
  background: #F2F2F2;
}

.reportCalorie ul {
  margin-top: 0px;
}

.classReport li {
  width: 100%;
  margin-top: 1px;
  background: #FFF;
  height: 66px;
  padding-left: 15px;
  float: left;
}

.classReport li:last-child {
  margin-bottom: 25px;
}

.classReport li .className {
  width: 23%;
  height: 28px;
  line-height: 25px;
  /*background: #EBFFE3;*/
  overflow: hidden;
  font-size: 14px;
  padding: 1px 11px;
  border-radius: 250px;
  float: left;
  margin-top: 20px;
  border: solid 1px #37CB00;
  text-align: center;
  color: #333;
}

.classReport li .calorieToTal {
  float: left;
  text-align: center;
  display: block;
  width: 25%;
  margin-top: 15px;
}

.calorieToTal em {
  width: 100%;
  overflow: hidden;
  color: black;
  display: block;
  font-size: 16px;
}

.calorieToTal span {
  width: 100%;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 10px;
  color: #C9C9C9;
}

.classDetailInfo {
  width: 100%;
  display: inline;
  float: left;
  margin-bottom: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-family: "PingFang SC";
  margin-top: 10px;
  color: black;
  background: #FFFFFF;
  text-align: center;
}

/*pageClass*/
.pageClass {
  text-align: center;
  margin-top: 15px;
}

.pageClass em {
  text-align: center;
  font-size: 16px;
}

.dayList .dayDetail {
  background: #FFF;
  margin-left: 5%;
  margin-right: 5%;
  height: 205px;
  text-align: center;
  border-radius: 6px;
  margin-top: 15px;
}

.dayDetail .dayTitle {
  font-size: 12px;
  float: right;
  margin-right: 23px;
  margin-top: 16px;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  font-weight: normal;
  color: #9A9A9A;
}

.dayDetail .className {
  width: 85px;
  height: 28px;
  line-height: 28px;
  background: #EBFFE3;
  overflow: hidden;
  font-size: 14px;
  padding: 1px 11px;
  border-radius: 250px;
  float: left;
  margin-left: 15px;
  margin-top: 13px;
  border: solid 1px #37CB00;
  text-align: center;
  color: #333;
}

.dayDetail .sportDetail {
  padding-top: 50px;
  text-align: center;
}

.sportDetail ul {
  width: 90%;
  margin-top: 5px;
  margin-left: 5%;
  height: 65px;
  border-bottom: 1px solid #EFEFEF;
}

.sportDetail ul li em i {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

.sportDetail li {
  width: 33%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  float: left;
}

.sportDetail li:nth-child(2) {
  border-color: #e2e2e2;
}

.sportDetail li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  font-size: 22px;
  font-weight: bold;
  text-align: right;
  padding-right: 8px;
}

.sportDetail li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: right;
  padding-right: 8px;
}

.sportDetail .sportDuration {
  text-align: center;
  margin: 0 auto;
  width: 90%;
  height: 40px;
  border-bottom: 1px solid #EFEFEF;
}

.sportDetail .sportDuration em {
  color: #028FE1;
  margin-left: 5px;
  margin-right: 5px;
}

.sportDetail .sportDuration span {
  font-size: 14px;
  line-height: 40px;
}

.sportDetail .sportView {
  height: 40px;
}

.sportDetail .sportView span {
  float: left;
  margin-left: 5%;
  margin-top: 10px;
  color: #848484;
}

.sportDetail .sportView em {
  float: right;
  font-weight: normal;
  color: #C4C4C4;
  margin-right: 5%;
  margin-top: 10px;
}


.pageDetail {
  text-align: center;
  background: #FFF;
  margin-top: 20px;
  height: 1200px;
}

.pageDetail .btnBack {
  float: left;
  margin-left: 15px;
  margin-top: 14px;
  font-size: 16px;
  overflow: hidden;
  display: block;
  font-family: "PingFang SC";
  color: #ACACAC;
}

.pageDetail .classDetail {
  display: inline-block;
  *display: inline;
  margin-right: 45px;
  zoom: 1;
  margin-top: 30px;
}

.pageDetail .detailTitle {
  float: left;
  background: #EBFFE3;
  border-radius: 12px;
  padding: 1px 15px;
  border: solid 1.5px #37CB00;
}

.detailTitle span {
  display: block;
}

.pageDetail .memberInfo {
  margin-top: 5px;
}

.memberInfo em {
  color: #FFA310;
  font-size: 12px;
}

.memberInfo span {
  font-size: 12px;
  color: #909090;
}

.classInfo {
  background: #FFF;
  text-align: center;
}

.classInfo ul {
  width: 80%;
  margin-left: 10%;
  height: 40px;
  border-bottom: 1px solid #CCC;
  padding-bottom: 50px
}

.classInfo li {
  width: 33%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  float: left;
}

.classInfo li:nth-child(2) {
  border-color: #e2e2e2;
}

.classInfo li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  color: #E75296;
  font-size: 14px;
  text-align: center;
}

.classInfo li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 10px;
  text-align: center;
}

/**
动作评分结果选项
 */

.pageScore {
  background: #F2F2F2;
  margin-top: 10px;
}

.pageScore .scoreTitle {
  background: #FFF;
}

.pageScore span {
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  text-align: center;
  line-height: 50px;
  font-family: "PingFang SC";
}

.pageScore .scoreContent {
  margin-top: 5px;
  font-weight: normal;
}

.pageScore .scoreContent li {
  width: 100%;
  background: #FFF;
  height: 50px;
  border-bottom: solid 1px #F2F2F2;
}

.pageScore .scoreContent li span {
  font-weight: normal;
}

.pageScore .scoreContent li:first-child span {
  font-weight: bold;
}

.pageScore .scoreContent li .left {
  width: 50%;
  float: left;
  line-height: 30px;
  text-align: right;
  padding-right: 10%;
  display: block;
  border-right: 1px solid #F2F2F2;
}

.pageScore .scoreContent li .right {
  /*background: #EBFFE3;*/
  width: 50%;
  float: left;
  text-align: left;
  padding-left: 10%;
  line-height: 30px;
  border-right: 1px solid #F2F2F2;
  display: block;
}

.pageScore .scoreContent li .right em {
  font-weight: normal;
  margin-right: 20px;
  color: #F2F2F2;
  font-size: 14px;
  float: right;
}

.user {
  width: 100%;
  overflow: hidden;
  display: block;
  margin-top: 120px;
  height: 58px;
  background: #fff;
  padding: 17px 3%;
}

.user img {
  width: 26.29px;
  height: 26.29px;
  float: left;
  margin-right: 11px;
}

.user span {
  font-family: "PingFang SC";
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  margin-left: 25px;
  color: #3b3b3b;
}

.user em {
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  margin-left: 20px;
  color: #3b3b3b;
}

.user p {
  font-family: "PingFang SC";
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #ffb43c;
  float: right;
  padding: 0;
  margin: 0 0 0 3px;
  line-height: 26px;
}

.vipInfo {
  width: 100%;
}

.vipInfo .shopName {
  height: 40px;
  line-height: 35px;
  text-align: center;
  font-size: 18px;
  color: #3B3B3B;
}

.vipInfo .infoDetail {
  font-size: 14px;
  color: #FF5800;
  background: #ffffff;
  height: 115px;
}

.infoDetail .chargeRecord {
  margin-right: 25px;
  margin-top: 12px;
  float: right;
  visibility: hidden;
  height: 15px;
}

.vipInfo .hourSUm {
  width: 100%;
  overflow: hidden;
  display: block;
  text-align: left;
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 15px;
}

.hourSUm span {
  color: #3B3B3B;
  margin-left: 26px;
  font-size: 16px;
}

.hourSUm em {
  color: black;
  margin-left: 10px;
  font-size: 12px;
  color: #C9C9C9;
}

.infoDetail .linear-progress{
  margin-top: 10px;
  margin-bottom: 10px;
}

/deep/ .linear-progress {
  width: 88%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  border-radius: 250px;
}

.limit {
  width: 100%;
  overflow: hidden;
  display: block;
}

.limit em {
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 12px;
  text-align: right;
  margin-left: 26px;
  color: #e75296;
}

.limit span {
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 12px;
  text-align: right;
  color: #909090;
  float: right;
  text-align: right;
  margin-right: 25px;
}

.btn_center {
  width: 100%;
  margin-bottom: 40px;
}

.login_btn {
  width: 94%;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #E75296;
  color: #fff;
  text-align: center;
  font-size: 18px;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 375px) {

}

@media only screen and (max-width: 360px) {

}

@media only screen and (max-width: 320px) {

}

@media only screen and (min-width: 641px) {

}
</style>
